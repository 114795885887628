.choose {
  background: #F1F1F1;
  padding: 50px 0;
}

.choose-inner {
  max-width: 1370px;
  padding: 0 15px;
  margin: 0 auto;
}

.choose-properties {
  width: 100%;
}

.choose-properties .button {
  margin-right: 30px;
}

.choose-content {
  display: flex;
  margin-top: 30px;
}

.choose-floors {
  position: relative;
  z-index: 10;
  width: 100px;
  flex: none;
}

.choose-floors .button {
  width: 80px;
  margin-bottom: 10px;
}

.choose-property {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  width: 100%;
  padding-right: 100px;
}

.choose-property__image {

}

.choose-property__floors {
  position: absolute;
  left: 0;
  bottom: 87px;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.choose-property__floor {
  flex: none;
  background: #141619;
  opacity: 0;
  padding: 0;
  margin: 0;
  border: 0;
  width: 30%;
  margin-left: 31%;
  height: 35px;
  cursor: pointer;
}

.choose-property__floor.is-hovered,
.choose-property__floor:hover{
  opacity: .6;
}

.choose-property__flats {
  position: absolute;
  left: 0;
  bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 69%;
  margin-left: 11%;
  
}

.choose-property__flat {
  position: relative;
  flex: none;
  background: rgba(20, 22, 25, 0);
  padding: 0;
  margin: 0;
  border: 0;
  height: 200px;
  cursor: pointer;
}

.choose-property__flat--2kk {
  width: 20%;
}

.choose-property__flat--1kk {
  width: 10%; 
}

.choose-property__flat.is-hovered,
.choose-property__flat:hover {
  background: rgba(20, 22, 25, .6);
}

.choose-property__flat.is-hovered .choose-flat,
.choose-property__flat:hover .choose-flat {
  display: block;
}

.choose-property__flat.is-disabled {
  background-color: rgba(172, 40, 50, .5);
}

.choose-flat {
  display: none;
  position: absolute;
  z-index: 20;
  left: 50%;
  bottom: 60%;
  transform: translateX(-50%);
  text-align: center;
  color: #141619;
  background-color: #fff;
  padding: 20px;
  min-width: 170px;
  line-height: 1.2;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
}

.choose-flat::before {
  content: '';
  position: absolute;
  top: 100%;
  margin-left: -5px;
  left: 50%;
  width: 0;
	height: 0;
	border-width: 12px 9px 0 9px;
	border-color: #FFFFFF transparent transparent transparent;
	border-style: solid;
}

.choose-flat__title {
  font-size: 20px;
  font-weight: 700;
}

.choose-flat__info {
  font-size: 13px;
  color: #83888D;
  margin-top: 4px;
}

.choose-flat__tag {
  padding: 5px 10px;
  background-color: #141619;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}

.choose-flat__tag-wrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.choose-flat__price {
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
}

.choose-flat__other {
  font-size: 10px;
  margin-top: 5px;
  color: #83888D;
}