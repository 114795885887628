.app {
  padding: 50px 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Degular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.inner {
  position: relative;
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 15px;
}

.button {
  position: relative;
  cursor: pointer;
  border: 1px solid #B5B9BD;
  font-size: 16px;
  font-weight: 600;
  color: #141619;
  background: rgba(0, 0, 0, 0);
  padding: 7px 12px;
  text-align: center;
}

.button__tag {
  display: none;
  position: absolute;
  left: 100%;
  top: -1px;
  background: #fff;
  padding: 0 20px;
  margin-left: 18px;
  white-space: nowrap;
  line-height: 35px;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1);
}

.button__tag::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: -8px;
  width: 0;
	height: 0;
	border-width: 5px 8px 5px 0;
	border-color: transparent #FFFFFF transparent transparent;
	border-style: solid;
}

.button--big {
  font-size: 20px;
  padding: 17px 30px;
}

.button:hover,
.button.is-hovered {
  border-color: #141619;
}

.button:hover .button__tag,
.button.is-hovered .button__tag {
  display: block;
}

.button.is-active {
  background: #141619;
  border-color: #141619;
  color: #fff;
}

.hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
}