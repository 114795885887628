.table {
  margin-top: 85px;
  padding-top: 25px;
  border-top: 1px solid #D2D5D9;
}

.table.is-active .table-header .table__opener svg {
  transform: rotate(0);
}

.table.is-active .table-buttons,
.table.is-active .table-data {
  position: static;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.table__heading {
  font-size: 32px;
}

.table__opener {
  margin-left: 20px;
  text-align: center;
}

.table__opener svg {
  fill: #141619;
  transition: transform .4s;
  transform-origin: 50% 50%;
  transform: rotate(180deg);
}

.table-buttons {
  margin-top: 5px;
}

.table-buttons__row {
  margin: 0 -5px;
}

.table-buttons__row .button {
  margin: 5px 5px;
}

.table-data {
  margin-top: 30px;
}

.table-data__header {
  border-bottom: 1px solid #141619;
  padding-bottom: 10px;
}

.table-data__items .table-data__row {
  border-bottom: 2px solid #F1F1F1;
}

.table-data__items .table-data__row:hover {
  background-color: #F1F1F1;
}

.table-data__row {
  display: flex;
}

.table-data__row.is-disabled {
  color: #B5B9BD;
}

.table-data__row.is-disabled .button {
  display: none;
}

.table-data__col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  width: 11.1%;
  text-align: center;
  padding: 10px 0;
  font-size: 13px;
  line-height: 30px;
}

.table-data__col .button {
  font-size: 13px;
}

.table-data__col svg {
  margin-left: 4px;
  fill: #D2D5D9;
}

.table-data__col:nth-child(1) {

}

.table-data__col:nth-child(2) {

}

.table-data__col:nth-child(3) {

}

.table-data__col:nth-child(4) {
  width: 8%;
}

.table-data__col:nth-child(5) {

}

.table-data__col:nth-child(6) {

}

.table-data__col:nth-child(7) {
  width: 14%;
}

.table-data__col:nth-child(8) {
  width: 16%;
}

.table-data__col:nth-child(9) {
  width: 13%;
  padding-right: 10px;
  justify-content: flex-end;
  margin-right: 0;
}