.property {

}

.property__wrap {
  position: relative;
}

.property__image {
  width: 100%;
}

.property__button {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  background: rgba(0, 0, 0, 0);
}

.property__button:hover svg {
  opacity: .5;
}

.property__button:hover .property-card {
  background-color: #303439;
}

.property__button--1 {
  top: 24%;
  left: 17.4%;
  width: 26.8%;
  height: 50.6%;
}

.property__button--1 svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.property__button--2 {
  top: 10%;
  left: 46.9%;
  width: 32.5%;
  height: 75.6%;
}

.property__button--2 svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.property-card {
  position: absolute;
  left: 20%;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 192px;
  min-height: 72px;
  padding: 5px 10px;
  background-color: #141619;
}

.property-card__name {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.property-card__info {
  color: #fff;
  font-size: 13px;
  margin-top: 3px;
}